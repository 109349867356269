import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import Defer from 'alpinejs-defer';
import Precognition from 'laravel-precognition-alpine';

// // Needed for Statamics form conditions
import '../../vendor/statamic/cms/resources/dist-frontend/js/helpers';

window.Alpine = Alpine;
window.Livewire = Livewire;

Alpine.directive('defer', Defer).before('ignore');


setTimeout(() => {
    Alpine.plugin(Precognition);
}, 0);
